import { render, staticRenderFns } from "./ExplanationDetails.vue?vue&type=template&id=706f4605&scoped=true"
import script from "./ExplanationDetails.vue?vue&type=script&lang=js"
export * from "./ExplanationDetails.vue?vue&type=script&lang=js"
import style0 from "./ExplanationDetails.vue?vue&type=style&index=0&id=706f4605&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706f4605",
  null
  
)

export default component.exports